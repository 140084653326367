import React from 'react';
import styles from '../../collections/PreSelectedFilter/PreSelectedFilter.module.scss';
import Flex from '../Flex';
import Skeleton from './Skeleton';

const FilterSkeleton = () => {
	return (
		<div className={styles.optionsWrapper} style={{ width: '100%', height: '100%' }}>
			<Flex gap={3} className={styles.optionsContainer}>
				{[...Array(7)].map((__, i) => (
					<Skeleton key={i} width={i==0 ? 50 :120} height={52} type='rect' borderRadius={'1rem'} />
				))}
			</Flex>
		</div>
	);
};

export default FilterSkeleton;
