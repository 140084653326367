/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import React, { useRef } from 'react';
import dynamic from 'next/dynamic';
import { useReducer, useMemo, useState, useCallback, useEffect } from 'react';
import Script from 'next/script';
import { Text } from '@contentful/rich-text-types';
import {
	BASE_FRAME_AGE_RANGES,
	BASE_FRAME_SIZING_OPTIONS,
	PRODUCT_TYPES,
	VTO_SRC,
} from '@constants';
import {
	FilterSkeleton,
	Hero,
	HeroSkeleton,
	InteractiveCard,
	InteractiveCardSkeleton,
	MarketingCard,
	Modal,
	PLPWrapper,
	PreSelectedFilter,
	ProductGrid,
	VerticalCard,
	VTO,
} from '@components';
import { BaseFrameState, baseFrameReducer } from '@utils/hooks/useBaseFrame';
import { ErrorBoundary, SEO } from '@components/utils';
import { trackProductListView } from '@services/analytics/trackers';
import { useDebounce, useHasMounted, useIsMobile } from '@utils/hooks';
import { capitalizeEachWord, getBaseName, makePossessive } from '@utils/strings';
import { getVariantByOption, FRAME_COLORS } from '@utils/index';
import { AdditionalInformationFields, CopyFields, HeroFields, MarketingCardFields, SeoMetadata } from '@ts/contentful';
import { NormalizedCollection } from '@ts/product';
import { Body } from '@components/typography';
import styles from './CollectionView.module.scss';

type CollectionViewProps = {
	/**
	 * Base Type for SEO Title
	 * 
	 * @note MUST be plural case
	 * @example Women's Eyeglasses
	 */
	baseType: 'Eyeglasses' | 'Sunglasses' | 'Blue Light Glasses';
	collection: NormalizedCollection;
	copyForView: {
		name: string;
		title: string;
		subtitle: string;
	};
	demo: string;
	hero: HeroFields; // Can be null if entry is not found or set to DRAFT in Contentful
	pathInfo: string;
	showVTO: boolean;
	interactiveCards?: MarketingCardFields[];
	seoMetadata?: SeoMetadata;
	additionalInformation?: AdditionalInformationFields;
};
const Compare = dynamic(() => import('@components/products/Compare/Compare'));

const CollectionView = ({
	hero,
	baseType,
	demo,
	collection,
	copyForView,
	pathInfo,
	interactiveCards,
	showVTO,
	seoMetadata,
	additionalInformation,
}: CollectionViewProps) => {
	const isMobile = useIsMobile();
	const isMounted = useHasMounted();
	const isPlpCompareTestVariant1Active = typeof window !== 'undefined' && window.isPlpCompareTestVariant1Active;
	const isPlpCompareTestVariant2Active = typeof window !== 'undefined' && window.isPlpCompareTestVariant2Active;
	const isSunglassesPLP = pathInfo.includes('sunglasses');
	const collectionHasSunLens = !!collection.products.find(p => p.type === PRODUCT_TYPES.LENS);
	const baseFrameProducts = collection.products.filter(p => p.type.includes(PRODUCT_TYPES.BASE_FRAME));

	const [filtersActive, setFiltersActive] = useState(false);
	const [allSelected, setAllSelected] = useState(true);
	const [activeFrameColor, setActiveFrameColor] = useState();
	const [activeSwatchColor, setActiveSwatchColor] = useState('Black');
	const [visibleProducts, setVisibleProducts] = useState(baseFrameProducts);
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const sizeOptions = demo === 'kids' ? BASE_FRAME_AGE_RANGES : BASE_FRAME_SIZING_OPTIONS;
	const heroRef = useRef<HTMLDivElement>();
	const seoMeta = seoMetadata ? {
		title: seoMetadata?.seoTitle,
		description: seoMetadata?.description,
		noindex: seoMetadata?.no_index,
		nofollow: seoMetadata?.no_follow,
		openGraph: {
			title: seoMetadata?.seoTitle,
			description: seoMetadata?.description,
		},
	} : { title: `${demo === 'all' ? 'All' : makePossessive(capitalizeEachWord(demo))} ${baseType}` }

	const debouncedTrackProductListView = useDebounce(trackProductListView);

	const initialVtoState: BaseFrameState = {
		product: baseFrameProducts[0],
		variant: baseFrameProducts[0].variants[0],
		frame: baseFrameProducts[0].variants[0].option as FRAME_COLORS,
		lens: null,
		image: baseFrameProducts[0].variants[0].image,
	};

	const initialFilterState = {
		width: [] as string[],
		shape: [] as string[],
		baseColor: [] as string[],
	};

	const filterReducer = (state: typeof initialFilterState, { type, value }) => {
		switch (type) {
			case 'add-width':
				return { ...state, width: [value], shape: [], baseColor: [] };
			case 'add-shape':
				return { ...state, shape: [value], width: [], baseColor: [] };
			case 'add-baseColor':
				return { ...state, baseColor: [value] };
			case 'remove-width':
				return { ...state, width: [] };
			case 'remove-shape':
				return { ...state, shape: [] };
			case 'remove-baseColor':
				return { ...state, baseColor: [] };
			case 'reset':
				return initialFilterState;
			default:
				return state;
		}
	};

	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);

	const [filters, setFilters] = useReducer(filterReducer, initialFilterState);

	const applyFiltersToProducts = useCallback(() => {
		let filteredProducts = baseFrameProducts;

		if (filters.width.length) {
			filteredProducts = filteredProducts.filter(({ name }) =>
				filters.width.includes(sizeOptions[getBaseName(name, false)])
			);
		}

		if (filters.shape.length) {
			filteredProducts = filteredProducts.filter(({ tags }) => {
				return tags.some(tag => filters.shape.includes(tag));
			});
		}

		// ! Skip the Color Filter, since it doesn't really filter anything

		return filteredProducts;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseFrameProducts, filters.shape, filters.width]);

	const triggerFilter = (forceActive = filtersActive) => {
		forceActive && setVisibleProducts(applyFiltersToProducts());
		forceActive && setActiveFrameColor(filters.baseColor[0]);
		!forceActive && setVisibleProducts(baseFrameProducts);
		!forceActive && setActiveFrameColor(undefined);
	};

	const memoizedHero = useMemo(
		() => {
			if (!hero || !isMounted) return null;
			// TODO remove this tag, title attributes. Update in contentful
			const fixedHero = { ...hero, tag: null, title: null, type: 'Full-Bleed' as HeroFields['type'] };

			return <ErrorBoundary skeleton={<HeroSkeleton size={isMobile ? 'small' : 'medium'} asBanner type='Full-Bleed'/>}><Hero ref={heroRef} size={isMobile ? 'small' : 'medium'} asBanner {...fixedHero} /></ErrorBoundary>;
		},
		[hero, isMobile, heroRef, isMounted]
	);

	const handleSwatchChange = useCallback(payload => {
		if (payload.type === 'frame') {
			setActiveSwatchColor(payload.option);
		}
	}, []);

	useEffect(() => {
		debouncedTrackProductListView({ products: visibleProducts, path: `${pathInfo}/` });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const cardLocations = interactiveCards?.map(card => card.blockLocation - 1)
	const cardsByLocation = interactiveCards?.reduce((acc, card) => {
		acc[card.blockLocation - 1] = (
			<ErrorBoundary skeleton={<InteractiveCardSkeleton/>}>
				<InteractiveCard
					key={card.title}
					background={card.background.url}
					title={card.title}
					text={(card.text.content[0].content[0] as Text).value}
					subtitle={card.subtitle}
					subheading={!card.shouldExpand ? (card.text.content[0].content[0] as Text).value : ''}
					shouldExpand={card.shouldExpand}
					buttonClassName={styles['button-container']}
					extraHeaderClassName={styles['header']}
					button1={{
						label: card.buttonLabel1,
						link: card.buttonLink1,
						color: card.buttonColor1,
						fullWidth: true,
					}}
					{...(card.buttonLabel2 && {
						button2: {
							label: card.buttonLabel2,
							link: card.buttonLink2,
							color: card.buttonColor2,
						},
					})}
					data-base-frame-marketing-block={card.title}
					size='flexible'
				/>
			</ErrorBoundary>
		);
		return acc
	}, {} as Record<number, JSX.Element>);

	return (
		<>
			<Modal>
				<ErrorBoundary>
					<SEO {...seoMeta} />
				</ErrorBoundary>
				{memoizedHero}
				<PLPWrapper {...additionalInformation}>
					<ErrorBoundary skeleton={<FilterSkeleton/>}>
						<PreSelectedFilter
							filterControl={{ filters, setFilters, filtersActive, setFiltersActive, triggerFilter }}
							allSelected={allSelected}
							setAllSelected={setAllSelected}
							heroRef={heroRef}
						/>
					</ErrorBoundary>
					<ProductGrid type='base'>
						{visibleProducts.length === 0 && (
							<Body className={styles['no-results']}>
								No Base Frames are available within these selected filters.
							</Body>
						)}
						{visibleProducts.map((product, index) => (
							<React.Fragment key={product.handle}>
								{(isPlpCompareTestVariant1Active || isPlpCompareTestVariant2Active) && index === 7 && allSelected && (
									<MarketingCard
										color={activeSwatchColor}
										handleSwatchChange={handleSwatchChange}
										product={baseFrameProducts.find((p) => p.handle === 'large-base')}
										isAccordian={false}
										showTags
										isPlp
									/>
								)}
								{allSelected && cardLocations?.some(location => location === index) && (
									cardsByLocation[index]
								)}
								<VerticalCard
									buttonGroupType='stacked'
									dispatch={setVtoState}
									key={product.handle}
									parentCollectionHandle={demo}
									primaryAction='view'
									product={product}
									secondaryAction={pathInfo.includes('sunglasses') ? 'none' : 'vto'}
									showAction
									showVariantControls
									showLensController={pathInfo.includes('sunglasses')}
									variant={activeFrameColor ? getVariantByOption(product, activeFrameColor) : null}
									label={`Design Your ${getBaseName(product.name, false)}`}
									supplemental={' '}
								/>
							</React.Fragment>
						))}
						{collectionHasSunLens &&
							visibleProducts.map(product => (
								<VerticalCard
									buttonGroupType='stacked'
									dispatch={setVtoState}
									key={product.handle}
									parentCollectionHandle={demo}
									primaryAction='view'
									product={product}
									secondaryAction={'none'}
									showAction
									showVariantControls
									showLensController
									variant={activeFrameColor ? getVariantByOption(product, activeFrameColor) : product.variants[0]}
									label={`Design Your ${getBaseName(product.name, false)}`}
									supplemental={' '}
								/>
							))}
						{allSelected && cardLocations?.filter(location => location >= visibleProducts.length).map(location => cardsByLocation[location])}
					</ProductGrid>
				</PLPWrapper>
				<Modal.Content
					removePadding
					customClose
					onCloseAutoFocus={e => e.preventDefault()}
					style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
				>
					<VTO FM={FitMix} state={vtoState} setState={setVtoState} products={baseFrameProducts} pathInfo={pathInfo} />
				</Modal.Content>
			</Modal>
			{isPlpCompareTestVariant2Active && !collectionHasSunLens && allSelected && (
				<div className={styles.compareContainer}>
					<Compare
						color={activeSwatchColor}
						product={baseFrameProducts.find(p => p.handle === 'large-base')}
						isAccordian={false}
						showTags
						callback={handleSwatchChange}
						isPlp
					/>
				</div>
			)}
			{showVTO && (
				<Script
					src={VTO_SRC}
					type='text/javascript'
					strategy='lazyOnload'
					onLoad={() => {
						setFitMix(window.FitMix);
					}}
				></Script>
			)}
		</>
	);
};

export default CollectionView;
